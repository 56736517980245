<template>
    <aside class="flex flex-col flex-shrink-0 w-[70px] bg-black p-3">
        <div class="flex items-center justify-center py-1.5 mb-6 flex-shrink-0">
            <NuxtLink to="/">
                <Logo class="h-8" />
            </NuxtLink>
        </div>
        <nav class="flex flex-col gap-4 flex-1">
            <SidebarLink to="/" icon="i-ph-house-bold" />
            <SidebarLink to="/history" icon="i-ph-hourglass-bold" />
        </nav>
        <div class="flex flex-col">
            <SidebarButton @click="clear" icon="i-ph-plugs-bold" class="hover:text-red-500 hover:bg-red-900 hover:bg-opacity-40" />
        </div>
        
    </aside>
</template>

<script setup lang="ts">
const { clear } = useUserSession();
</script>
