<template>
    <div class="h-screen overflow-hidden flex flex-col">
        <main class="flex-grow overflow-hidden relative">
            <div class="grid grid-rows-[1fr] grid-cols-[auto_1fr] h-full w-full overflow-hidden">
                <Sidebar  />
                
                <slot />
            </div>
        </main>
    </div>
</template>
