<template>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
        y="0px" viewBox="0 0 116 128" style="enable-background:new 0 0 116 128;" xml:space="preserve">
        
        <g>
            <polygon class="st0" fill="#777" points="55,0 32.77,12.93 75.55,37.82 55,49.78 12.22,24.89 0,32 0,96 55,128 77.23,115.07 22.23,83.07 
		22.23,59.16 55,78.22 87.77,59.16 87.77,108.93 110,96 110,32 55,0 	" />
            <path class="st1"
                fill="#444"
                d="M22.23,108.93l29.68-8.59L22.23,83.07V108.93z M68.09,33.48l7.46,4.34l22.23-12.93L68.09,33.48z" />
        </g>
    </svg>
</template>
